<template>
  <div class="p-2">
        <ValidationObserver ref="datos_basicos">

         <!--   <div>         
              <validation-provider
                v-slot="{ errors }"
                name="fields_representante.group_datos_basicos.nu_id_parentesco.nu_id_parentesco"
                :rules=fields_representante.group_datos_basicos.nu_id_parentesco.caracteristicas.required
              >
                  <v-select
                    v-model="fields_representante.group_datos_basicos.nu_id_parentesco.nu_id_parentesco"
                    :items="d_parentesco"
                    class="input-group--focused text-lg pt-4"
                    :error-messages="errors"
                    :label=fields_representante.group_datos_basicos.nu_id_parentesco.caracteristicas.label
                    outlined
                    :disabled=!fields_representante.isEditing                            
                  ></v-select>                      
              </validation-provider>   
            </div>
          -->
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_representante.group_datos_basicos.tx_apellidos.tx_apellidos"
                rules="required"
              >
                <v-text-field
                  v-model="fields_representante.group_datos_basicos.tx_apellidos.tx_apellidos"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_representante.group_datos_basicos.tx_apellidos.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_representante.isEditing                            
                ></v-text-field>
              </validation-provider>     
                </template>
                <span>{{fields_representante.group_datos_basicos.tx_apellidos.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">


            <validation-provider
                v-slot="{ errors }"
                name="fields_representante.group_datos_basicos.tx_nombres.tx_nombres"
                rules="required"
              >
                  <v-text-field
                    v-model="fields_representante.group_datos_basicos.tx_nombres.tx_nombres"
                    class="input-group--focused  text-lg"
                    :error-messages="errors"
                    :label=fields_representante.group_datos_basicos.tx_nombres.caracteristicas.label
                    v-bind="attrs"
                    v-on="on"
                    :disabled=!fields_representante.isEditing                            
                    outlined
                    ></v-text-field>
              </validation-provider>  
              </template>
              <span>{{ fields_representante.group_datos_basicos.tx_nombres.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>
          <div>
            <validation-provider
              v-slot="{ errors }"
              name="fields_representante.group_datos_basicos.tx_nacionalidad.tx_nacionalidad"
              :rules=fields_representante.group_datos_basicos.tx_nacionalidad.caracteristicas.required
            >
                    <v-select
                      v-model="fields_representante.group_datos_basicos.tx_nacionalidad.tx_nacionalidad"
                      :items="d_nacionalidad"
                      class="input-group--focused text-lg"
                      :placeholder=fields_representante.group_datos_basicos.tx_nacionalidad.caracteristicas.placeholder
                      :error-messages="errors"
                      :label=fields_representante.group_datos_basicos.tx_nacionalidad.caracteristicas.label
                      outlined
                      :disabled=!fields_representante.isEditing
                    ></v-select>                      

            </validation-provider>
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="fields_representante.group_datos_basicos.tx_documento_identidad.tx_documento_identidad"
                      :rules=fields_representante.group_datos_basicos.tx_documento_identidad.caracteristicas.required
                    >

                      <v-text-field
                        v-model="fields_representante.group_datos_basicos.tx_documento_identidad.tx_documento_identidad"
                        class="input-group--focused text-lg"
                        :error-messages="errors"
                        :label=fields_representante.group_datos_basicos.tx_documento_identidad.caracteristicas.label 
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        maxlength="10"
                        :disabled=!fields_representante.isEditing                            
                      >
                      </v-text-field>
                    </validation-provider>                    
                </template>
                <span>{{fields_representante.group_datos_basicos.tx_documento_identidad.caracteristicas.tooltip  }} </span>
              </v-tooltip>                

          </div>
            <div>         
              <validation-provider
                v-slot="{ errors }"
                name="fields_representante.group_datos_basicos.nu_sexo.nu_sexo"
                :rules=fields_representante.group_datos_basicos.nu_sexo.caracteristicas.required
              >
                  <v-select
                    v-model="fields_representante.group_datos_basicos.nu_sexo.nu_sexo"
                    :items="d_sexo"
                    class="input-group--focused text-lg pt-4"
                    :error-messages="errors"
                    :label=fields_representante.group_datos_basicos.nu_sexo.caracteristicas.label
                    outlined
                    :disabled=!fields_representante.isEditing                            
                  ></v-select>                      
              </validation-provider>   
            </div>

          <div>
              <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">

                  <validation-provider
                  v-slot="{ errors }"
                  name="fields_representante.group_datos_basicos.tx_direccion.tx_direccion"
                  rules="required"
                  >
                      <v-textarea
                          v-model="fields_representante.group_datos_basicos.tx_direccion.tx_direccion"
                          auto-grow
                          v-bind="attrs"
                          v-on="on"
                          class="input-group--focused text-lg"
                          :error-messages="errors"
                          :label=fields_representante.group_datos_basicos.tx_direccion.caracteristicas.label                
                          outlined
                          :disabled=!fields_representante.isEditing
                      ></v-textarea>                      
                  </validation-provider>   
              </template>
              <span>{{ fields_representante.group_datos_basicos.tx_direccion.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">

              <validation-provider
                  v-slot="{ errors }"
                  name="fields_representante.group_datos_basicos.tx_correo.tx_correo"
                  rules="email"
              >
                  <v-text-field
                      v-model="fields_representante.group_datos_basicos.tx_correo.tx_correo"
                      class="input-group--focused mb-10 text-lg"
                      :error-messages="errors"
                      label="Correo Electrónico"   
                      v-bind="attrs"
                      v-on="on"
                      :disabled=!fields_representante.isEditing
                      outlined
                  ></v-text-field>
              </validation-provider>
              </template>
              <span>{{ fields_representante.group_datos_basicos.tx_correo.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">

              <validation-provider
                  v-slot="{ errors }"
                  name="fields_representante.group_datos_basicos.tx_telefono_celular.tx_telefono_celular"
              >
                  <v-text-field
                  v-model="fields_representante.group_datos_basicos.tx_telefono_celular.tx_telefono_celular"
                  class="input-group--focused mb-10 text-lg"
                  v-mask="'(###) ###-####'"
                  :error-messages="errors"
                  label="Teléfono celular"
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_representante.isEditing
                  outlined
                  ></v-text-field>
              </validation-provider>  
              </template>
              <span>{{ fields_representante.group_datos_basicos.tx_telefono_celular.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
            <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">

            <validation-provider
                v-slot="{ errors }"
                name="fields_representante.group_datos_basicos.tx_telefono_local.tx_telefono_local"
                rules=""
            >
                <v-text-field
                v-model="fields_representante.group_datos_basicos.tx_telefono_local.tx_telefono_local"
                class="input-group--focused mb-10 text-lg"
                v-mask="'(###) ###-####'"                            
                :error-messages="errors"
                label="Teléfono local"
                v-bind="attrs"
                v-on="on"
                :disabled=!fields_representante.isEditing
                outlined
                ></v-text-field>
            </validation-provider>  
            </template>
            <span>{{ fields_representante.group_datos_basicos.tx_telefono_local.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>
                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_PERFIL  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'PacienteRepresentantelDatosBasicos',
  data () {
    return {
        modal: false,                 
        search:null,
        isLoading: false,
        list_perfiles: [],
        perfiles: [],
        d_nacionalidad: [
            { value: 'V', text: 'Venezolana' },
            { value: 'E', text: 'Extranjera', 
              disabled: false 
            }
          ],
        d_parentesco: [
                  { value: 1, text: 'Padre' },
                  { value: 2, text: 'Madre' },
                  { value: 3, text: 'Hermano / Hermana' },
                  { value: 4, text: 'Primo / Prima' },
                  { value: 5, text: 'Amigo / Amiga', 
                    disabled: false 
                  }
                ],                        
        d_sexo: [
                  { value: 1, text: 'Femenino' },
                  { value: 2, text: 'Masculino', 
                    disabled: false 
                  }
                ],                        
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
 
  },     
  computed: {
    ...mapGetters(['fields_representante', 'fields_usuario']),
      listaPerfiles () {
          return this.list_perfiles.map(entry => {
              const Description = entry.tx_nombre_perfil.length > this.descriptionLimit
              ? entry.tx_nombre_perfil.slice(0, this.descriptionLimit) + '...'
              : entry.tx_nombre_perfil

              return Object.assign({}, entry, { Description })
          })
      },       
  },
  props: {
    send_representante: Object
  },
  components: {

  },    
  methods: {
    searchPerfiles(q) {
      this.$store.dispatch(LIST_PERFIL, {q: q}).then(response => {
          console.log('searchLIST_PERFIL: ', response )
          this.list_perfiles = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },    
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        
        this.$emit("guardar_representante_datos_basicos", JSON.stringify(this.fields_representante.group_datos_basicos));
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_representante_datos_basicos", JSON.stringify(this.fields_representante.group_datos_basicos));


    }, 
          




  },
  
  mounted() {
      //this.searchPerfiles()
      //console.log('Tx usuario: ', this.fields_representante.group_datos_basicos.json_usuario_perfil.json_usuario_perfil)
    if (this.send_representante.operacion === 'EDITAR') {
        //this.fields_representante.group_datos_basicos.tx_apellidos.tx_apellidos = JSON.parse(this.send_representante.usuario.tx_apellidos)
        //this.fields_representante.group_datos_basicos.json_usuario_perfil.json_usuario_perfil = JSON.parse(this.send_representante.usuario.json_usuario_perfil)
          // for (var prop in this.send_representante.usuario) {
          //     if(typeof this.fields_representante[prop] === 'object') {
          //           if (this.fields_representante[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_representante[prop][prop] = JSON.parse(this.send_representante.usuario[prop])
          //           } else {
          //             this.fields_representante[prop][prop] = this.send_representante.usuario[prop]
          //           }
          //     } else {
          //           this.fields_representante[prop][prop] = this.send_representante.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        this.fields_representante.isEditing = true
    }       
  },
    
}
</script>