<template>
<div >
    <div class="p-2 border-2  rounded-md">
        <div>
            <PacienteRepresentanteRegistrarHeader :titulo="titulo "  />        
        </div>
        <div>
            <span class="text-sm md:text-lg text-center uppercase text-blue-500">
                <!--<strong> 
                    Paciente: 
                </strong>{{ send_representante.paciente.tx_nombres }} {{ send_representante.paciente.tx_apellidos }}-->
            </span>
        </div>       
      <div>
          
        <PacienteRepresentanteDatosBasicos  @guardar_representante_datos_basicos="return_representante_datos_basicos" :send_representante="send_representante">
        </PacienteRepresentanteDatosBasicos>
        
      </div>       
    </div> 

</div>
</template>
<script>
import { mapGetters } from 'vuex'
import PacienteRepresentanteRegistrarHeader from "@/components/comunes/HeaderModulo.vue";
import PacienteRepresentanteDatosBasicos from "@/components/paciente/PacienteRepresentantelDatosBasicos.vue";
import { CREATE_REPRESENTANTE, UPDATE_REPRESENTANTE } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

export default {
    name: 'PacienteRepresentanteRegistrar',
    data() {
        return {
            titulo: "Registrar Representante",
            mensaje: {
                satisfactorio: {
                status: false,
                texto: ''
                },
                nosatisfactorio: {
                status: false,
                texto: ''
                },
            },            
        }
    },
    props: {
        send_representante: Object

    },
    components: {
      PacienteRepresentanteDatosBasicos,
      PacienteRepresentanteRegistrarHeader
    },
    computed: {
        ...mapGetters(['fields_representante']),
    },
    methods: {        
        // Paso #1        
        return_representante_datos_basicos (msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
              console.log('guardar Actualizar: ', guardar)

            const formData = new FormData()

            formData.append('representante', JSON.stringify(guardar))

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (this.send_representante.operacion === 'EDITAR') {
              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_REPRESENTANTE, formData).then(datos => {
                guardar.btn_guardar = 'true';
                this.fields_historia_clinica.group_historia_clinica.nu_id_paciente.nu_id_paciente = datos.nu_id_paciente
                this.fields_historia_clinica.group_historia_clinica.tx_historia_clinica.tx_historia_clinica = datos.tx_historia_clinica
                this.fields_historia_clinica.group_historia_clinica.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                     // this.isLoading = false
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

          } else {
            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_REPRESENTANTE, formData).then(datos => {
                //this.searchPaciente({})
                //this.fields.paciente = this.select_paciente.nu_id_representante
                this.mensaje.satisfactorio.texto = "Registro de representante fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                // this.isHistoria = true
                // this.datos_historia_clinica.datos_persona = JSON.parse(datos.datos_persona)
                // this.datos_historia_clinica.datos_paciente = JSON.parse(datos.datos_paciente)
                // this.fields_historia_clinica.group_historia_clinica.nu_id_paciente.nu_id_paciente = this.datos_historia_clinica.datos_paciente.nu_id_paciente
                // this.fields_historia_clinica.group_historia_clinica.tx_historia_clinica.tx_historia_clinica = this.datos_historia_clinica.datos_paciente.tx_historia_clinica
                // this.fields_historia_clinica.group_historia_clinica.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                      this.isLoading = false
                  //    this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                      console.log('message emit from child component', datos)
                      this.mensaje.satisfactorio.status = false
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' Creado satisfactoriamente!'
                      });    
                      
                    this.$emit("guardar_representante_datos_basicos", JSON.stringify(guardar));
                      

                  },3000)                  
                  

                
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
                this.mensaje.nosatisfactorio.texto = "Registro de paciente cancelado"
                this.mensaje.nosatisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseMotivo')
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             

            })  

          }     
// hasta     

            console.log("Datos a enviar: " , guardar);
            console.log("Datos a enviar send_representante 01: " , this.send_representante);


            //this.select_paciente = JSON.parse(msg);[


        },             
    },
    mounted() {
    if (this.send_representante.operacion === 'EDITAR') {
        this.fields_representante.group_datos_basicos.json_institucion.json_institucion = JSON.parse(this.send_representante.usuario.json_institucion)
        this.fields_representante.group_datos_basicos.json_representante.json_representante = JSON.parse(this.send_representante.usuario.json_representante)
    }        

        //  console.log('this.send_representante: ', this.send_representante.paciente.tx_nombre_completo )
        //this.titulo += " " + this.send_representante.paciente.tx_nombre_completo;
    },         

  
}
</script>