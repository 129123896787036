import { extend } from 'vee-validate'
import {  required, email, numeric, regex, min, max } from 'vee-validate/dist/rules'

  
extend('Mayor', (value, [otherValue]) => {
  console.log("Valor: ", value)
  console.log("[otherValue: ", otherValue)
  if ( Number(value) < Number(otherValue)) {
    return `Valor debe ser mayor o igual a ${otherValue}`;
  }

  return true;
});


extend("nulo", {
  ...required,
  message: "No puede estar vacio",
});

extend('objectNotEmpty', {
  validate: (value,[valor1, valor2]) => {
    console.log('paso', value)
    console.log('paso1', valor1)
    console.log('paso2', valor2)
    if (Object.keys(value).length > 0) {
      return true;
    }
    return "Mensaje";
  },
});


extend('regex', {
  ...regex,
  message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  regex digitos  seguido un guión, terminando con un digito'
})

extend('rif', {
  ...regex,
  message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  ocho(8)11 digitos  seguido un guión, terminando con un digito'
})
extend('tele', {
  ...regex,
  message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  ocho(8)222 digitos  seguido un guión, terminando con un digito'
})

extend('min', {
  ...min,
  message: 'Valor minimo 5'
})

extend("required", {
  ...required,
  message: "No puede estar vacio",
});

extend("max", {
  ...max,
  message: "No puede ser mayor a {length} caracteres",
});

extend('email', {
  ...email,
  message: 'Debe introducir un correo valido'
})
extend('numeric', {
  ...numeric,
  message: 'Debe introducir solo números'
})
