<template>
    <div>

        <div class="p-4 m-2 text-lg text-skin-base bg-skin-fill rounded-lg">
          <v-text-field
            v-model="search"
            clearable
            :prepend-inner-icon=icons.mdiMagnify
            label="Buscar paciente"
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="pacientes"
            :items-per-page="5"
            :search="search"        
            class="elevation-1"
          >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="registrar_consulta(item)"
                >
                  {{ icons.mdiBookPlusMultiple }}
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="editar(item)"
                >
                  {{ icons.mdiPencil }}        
                </v-icon>
<!--                <v-icon
                  small
                  @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                >
                  {{ icons.mdiImageSearchOutline }}                
                </v-icon>
-->                
              </template>
                <!-- name -->
                <template #[`item.tx_nombres`]="{item}">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_nombres }}</span>
    <!--                <small>{{ item.tx_nombres }}</small> -->
                  </div>
                </template>          
                <template #[`item.tx_apellidos`]="{item}">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_apellidos }}</span>
    <!--                <small>{{ item.tx_nombres }}</small> -->
                  </div>
                </template>          

          </v-data-table>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogPaciente" :value="true" @input="dialogPaciente = false"                   
            >
            <v-card>
              <v-container>
              <div class="w-full md:w-1/2 mx-auto my-auto">
                <PacienteRegistrar titulo="Editar datos del paciente"  @guardar_paciente="return_paciente" :send_paciente="send_paciente"/>
              </div>

              </v-container>

            </v-card>
          </v-dialog>             

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import { MUESTRA_PACIENTE } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import PacienteRegistrar from "@/components/paciente/PacienteRegistrar.vue";

export default {
    name: 'PacienteListar',
    data() {
        return {
            dialogPaciente: false, 
            send_paciente: {},
            search: '',
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'nu_id_paciente',
              },          
              { 
                text: 'Identidad', 
                value: 'tx_documento_identidad' 
              },
              { 
                text: 'Apellidos', 
                value: 'tx_apellidos' 
              },
              { 
                text: 'Nombres', 
                value: 'tx_nombres' 
              },
              { 
                text: 'Sexo', 
                value: 'nu_sexo' 
              },
              { 
                text: 'Teléfono local', 
                value: 'tx_telefono_local' ,
                sortable: false,
              },
              { 
                text: 'Teléfono celular', 
                value: 'tx_telefono_celular', 
                sortable: false,

              },
              { 
                text: 'Correo electrónico', 
                value: 'tx_correo', 
                sortable: false,

              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },          
            ],            
        }
    },
    props: {
        pacientes: []

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_paciente.paciente = id
      this.send_paciente.operacion = 'EDITAR'
      this.dialogPaciente = true
    },
    return_paciente(msg) {
        //this.buscarPersona('params')
        var guardar_paciente = JSON.parse(msg);
        //this.select_paciente = JSON.parse(msg);
        console.log("return_paciente Paciente Listar: " , guardar_paciente);

        
        if (guardar_paciente.btn_guardar == 'true') {
            this.searchPaciente({})
            //this.fields.paciente = this.select_paciente.nu_id_representante
            console.log("Guarda ultima: " + guardar_paciente.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_paciente.btn_guardar);
        }
        //this.$emit("guardar_paciente", guardar_paciente);
        this.dialogPaciente = false
        //this.send_paciente = msg;
    },    

    registrar_consulta() {

    },      
    searchPaciente(q) {
        this.$store.dispatch(MUESTRA_PACIENTE, {q: q}).then(response => {
            this.pacientes = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },
  
    }, 
    components: {
      PacienteRegistrar
    },
    mounted() {
      //this.searchPaciente({})
      //console.log('Pacientes: ', this.pacientes)
    },
    watch: {
    },    


}
</script>