<template>
    <div>
        {{ select_representante }}
        <div  v-if="!isHiddenRepresentante">
                <v-btn
                color="primary"
                class="w-full"
                @click="tomar_inf_contacto()"
                >
                Tomar los datos del Representante
                </v-btn>                        
        </div> 
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_telefono_local.tx_telefono_local"
            rules=""
        >
            <v-text-field
            v-model="fields_paciente.tx_telefono_local.tx_telefono_local"
            class="input-group--focused mb-10 text-lg"
            v-mask="'(###) ###-####'"                            
            :error-messages="errors"
            label="Teléfono local"
            v-bind="attrs"
            v-on="on"
            :disabled=!fields_paciente.isEditing
            outlined
            ></v-text-field>
        </validation-provider>  
        </template>
        <span>{{ fields_paciente.tx_telefono_local.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_telefono_celular.tx_telefono_celular"
        >
            <v-text-field
            v-model="fields_paciente.tx_telefono_celular.tx_telefono_celular"
            class="input-group--focused mb-10 text-lg"
            v-mask="'(###) ###-####'"
            :error-messages="errors"
            label="Teléfono celular"
            v-bind="attrs"
            v-on="on"
            :disabled=!fields_paciente.isEditing
            outlined
            ></v-text-field>
        </validation-provider>  
        </template>
        <span>{{ fields_paciente.tx_telefono_celular.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_correo.tx_correo"
            rules="email"
        >
            <v-text-field
                v-model="fields_paciente.tx_correo.tx_correo"
                class="input-group--focused mb-10 text-lg"
                :error-messages="errors"
                label="Correo Electrónico"   
                v-bind="attrs"
                v-on="on"
                :disabled=!fields_paciente.isEditing
                outlined
            ></v-text-field>
        </validation-provider>
        </template>
        <span>{{ fields_paciente.tx_correo.caracteristicas.tooltip  }} </span>
        </v-tooltip>                

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

    export default {
        data () {
            return {
                select_representante:'',
                isHiddenRepresentante: true,
            }
        },
        computed: {
            ...mapGetters(['fields_paciente']),
        },
        
    }
    
</script>

<style lang="sass" scoped>

</style>