<template>
    <div>
        <div class="p-4 m-2 text-lg text-skin-base bg-skin-fill rounded-lg">
                    {{  titulo }}                    
        </div>        
    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"

export default {
    name: 'PacienteHeader',
    data() {
        return {
            dialogPaciente: false, 
            send_paciente: {},
        }
    },
    props: {
        titulo: String
    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    }, 
    components: {
    }     
}
</script>