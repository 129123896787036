var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(_vm.select_representante)+" "),(!_vm.isHiddenRepresentante)?_c('div',[_c('v-btn',{staticClass:"w-full",attrs:{"color":"primary"},on:{"click":function($event){return _vm.tomar_inf_contacto()}}},[_vm._v(" Tomar los datos del Representante ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_telefono_local.tx_telefono_local","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":"Teléfono local","disabled":!_vm.fields_paciente.isEditing,"outlined":""},model:{value:(_vm.fields_paciente.tx_telefono_local.tx_telefono_local),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_telefono_local, "tx_telefono_local", $$v)},expression:"fields_paciente.tx_telefono_local.tx_telefono_local"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_telefono_local.caracteristicas.tooltip)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_telefono_celular.tx_telefono_celular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":"Teléfono celular","disabled":!_vm.fields_paciente.isEditing,"outlined":""},model:{value:(_vm.fields_paciente.tx_telefono_celular.tx_telefono_celular),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_telefono_celular, "tx_telefono_celular", $$v)},expression:"fields_paciente.tx_telefono_celular.tx_telefono_celular"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_telefono_celular.caracteristicas.tooltip)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_correo.tx_correo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":"Correo Electrónico","disabled":!_vm.fields_paciente.isEditing,"outlined":""},model:{value:(_vm.fields_paciente.tx_correo.tx_correo),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_correo, "tx_correo", $$v)},expression:"fields_paciente.tx_correo.tx_correo"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_correo.caracteristicas.tooltip)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }