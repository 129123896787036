var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.fields_paciente.bo_posee_cedula == 'false')?_c('div',[_c('v-btn',{staticClass:"w-full",attrs:{"color":"primary"},on:{"click":function($event){return _vm.tomar_ubi_geo()}}},[_vm._v(" Tomar los datos del Representante ")])],1):_vm._e(),_c('validation-provider',{attrs:{"name":"fields_paciente.json_estado_co.json_estado_co","rules":_vm.fields_paciente.json_estado_co.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listaEntidades,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_desc_entidad","item-value":"tx_cod_entidad","label":_vm.fields_paciente.json_estado_co.caracteristicas.label,"placeholder":_vm.fields_paciente.json_estado_co.caracteristicas.placeholder,"prepend-icon":"","return-object":"","outlined":"","disabled":!_vm.fields_paciente.isEditing},on:{"change":_vm.searchMunicipio},model:{value:(_vm.fields_paciente.json_estado_co.json_estado_co),callback:function ($$v) {_vm.$set(_vm.fields_paciente.json_estado_co, "json_estado_co", $$v)},expression:"fields_paciente.json_estado_co.json_estado_co"}})]}}])}),_c('validation-provider',{attrs:{"name":"fields_paciente.json_municipio_co.json_municipio_co","rules":_vm.fields_paciente.json_municipio_co.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.codigosMunicipio,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_desc_municipio","item-value":"tx_cod_municipio","label":_vm.fields_paciente.json_municipio_co.caracteristicas.label,"placeholder":_vm.fields_paciente.json_municipio_co.caracteristicas.placeholder,"prepend-icon":"","return-object":"","outlined":"","disabled":!_vm.fields_paciente.isEditing},on:{"change":_vm.searchParroquia},model:{value:(_vm.fields_paciente.json_municipio_co.json_municipio_co),callback:function ($$v) {_vm.$set(_vm.fields_paciente.json_municipio_co, "json_municipio_co", $$v)},expression:"fields_paciente.json_municipio_co.json_municipio_co"}})]}}])}),_c('validation-provider',{attrs:{"name":"fields_paciente.json_parroquia_co.json_parroquia_co","rules":_vm.fields_paciente.json_parroquia_co.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.codigosParroquia,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_desc_parroquia","item-value":"tx_cod_parroquia","label":_vm.fields_paciente.json_parroquia_co.caracteristicas.label,"placeholder":_vm.fields_paciente.json_parroquia_co.caracteristicas.placeholder,"prepend-icon":"","outlined":"","return-object":"","disabled":!_vm.fields_paciente.isEditing},model:{value:(_vm.fields_paciente.json_parroquia_co.json_parroquia_co),callback:function ($$v) {_vm.$set(_vm.fields_paciente.json_parroquia_co, "json_parroquia_co", $$v)},expression:"fields_paciente.json_parroquia_co.json_parroquia_co"}})]}}])}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_direccion.tx_direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"auto-grow":"","error-messages":errors,"label":_vm.fields_paciente.tx_direccion.caracteristicas.label,"outlined":"","disabled":!_vm.fields_paciente.isEditing},model:{value:(_vm.fields_paciente.tx_direccion.tx_direccion),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_direccion, "tx_direccion", $$v)},expression:"fields_paciente.tx_direccion.tx_direccion"}},'v-textarea',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_direccion.caracteristicas.tooltip)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }