<template>
    <div class="text-skin-base w-full">
                <div class="md:flex  justify-end md:justify-start w-full">                  
                    <div class="my-auto p-2 md:pl-10 w-full md:w-1/5">
                        <button @click="registrar_paciente()" class="w-full font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-4 mt-1 m-1">
                            Crear paciente
                        </button>                          
                    </div>
<!--                            @change="verificar_frecuencia()"                        
-->        
                </div>


        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogPaciente" :value="true" @input="dialogPaciente = false"                   
            >
            <v-card>
              <v-container>
              <div class="w-full md:w-1/2 mx-auto my-auto">
                <PacienteRegistrar titulo="Crear paciente" @guardar_paciente="return_paciente" :send_paciente="send_paciente"/>
              </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>
<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import { READ_PACIENTE } from '@/store/actions.type'
//import { SET_ERROR } from '@/store/mutations.type'
import PacienteRegistrar from "@/components/paciente/PacienteRegistrar.vue";

export default {
    name: 'PacienteOpciones',
    data() {
        return {
            dialogPaciente: false,
            buscarPaciente: false, 
            send_paciente: {},
            datos_paciente: [],
            isLoading: false,
            json_paciente: ''
        }
    },
    props: {
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
        registrar_paciente () {
            this.dialogPaciente = true
            this.send_paciente.operacion = 'REGISTRAR'
            this.send_paciente.paciente = this.json_paciente
            // console.log(value) // someValue
        },
        return_paciente(msg) {
            //this.buscarPersona('params')
            var guardar_paciente = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);
            console.log("return_paciente guardar_paciente: " , guardar_paciente);

            
            this.$emit("guardar_paciente", guardar_paciente);
            this.dialogPaciente = false
            //this.send_paciente = msg;
        },    

    },
    computed: {
    }, 
    components: {
        PacienteRegistrar
    },
    mounted() {
        //this.searchPaciente()
    },     
}
</script>