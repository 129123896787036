<template>
    <div>
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_facebook.tx_facebook"
            rules=""
        >
            <v-text-field
            v-model="fields_paciente.tx_facebook.tx_facebook"
            class="input-group--focused mb-10 text-lg"
            :error-messages="errors"
            :label=fields_paciente.tx_facebook.caracteristicas.label
            v-bind="attrs"
            v-on="on"
            :disabled=!fields_paciente.isEditing
            outlined
            ></v-text-field>
        </validation-provider>    
        </template>
        <span>{{ fields_paciente.tx_facebook.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_instagram.tx_instagram"
            rules=""
        >
            <v-text-field
                v-model="fields_paciente.tx_instagram.tx_instagram"
                class="input-group--focused mb-10 text-lg"
                :error-messages="errors"
                :label=fields_paciente.tx_instagram.caracteristicas.label
                v-bind="attrs"
                v-on="on"
                :disabled=!fields_paciente.isEditing
                outlined
            ></v-text-field>
        </validation-provider>                    
        </template>
        <span>{{ fields_paciente.tx_instagram.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_twitter.tx_twitter"
            rules=""
        >
            <v-text-field
                v-model="fields_paciente.tx_twitter.tx_twitter"
                class="input-group--focused mb-10 text-lg"
                :error-messages="errors"
                :label=fields_paciente.tx_twitter.caracteristicas.label
                v-bind="attrs"
                v-on="on"
                :disabled=!fields_paciente.isEditing
                outlined    
            ></v-text-field>
        </validation-provider>                    
        </template>
        <span>{{ fields_paciente.tx_twitter.caracteristicas.tooltip  }} </span>
        </v-tooltip>                

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

    export default {
        name: 'PacienteDatosRedesSociales',
        data () {
            return {

            }
        },
        computed: {
            ...mapGetters(['fields_paciente']),
        },

        
        
    }
</script>

<style lang="sass" scoped>

</style>