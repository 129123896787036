<template>
  <div class="">
        <div class="">

itemsRepresentante {{ fields_paciente.nu_id_representante.nu_id_representante }}
            <div>
                      <div>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">

                          <validation-provider
                            v-slot="{ errors }"
                            name="fields_paciente.tx_apellidos.tx_apellidos"
                            rules="required"
                          >
                            <v-text-field
                              v-model="fields_paciente.tx_apellidos.tx_apellidos"
                              outlined
                              class="input-group--focused text-lg pt-2"
                              :error-messages="errors"
                              :label=fields_paciente.tx_apellidos.caracteristicas.label
                              v-bind="attrs"
                              v-on="on"
                              :disabled=!fields_paciente.isEditing                            
                            ></v-text-field>
                          </validation-provider>     
                            </template>
                            <span>{{fields_paciente.tx_apellidos.caracteristicas.tooltip  }} </span>
                          </v-tooltip>                
                      </div>
                      <div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">


                        <validation-provider
                            v-slot="{ errors }"
                            name="fields_paciente.tx_nombres.tx_nombres"
                            rules="required"
                          >
                              <v-text-field
                                v-model="fields_paciente.tx_nombres.tx_nombres"
                                class="input-group--focused  text-lg"
                                :error-messages="errors"
                                :label=fields_paciente.tx_nombres.caracteristicas.label
                                v-bind="attrs"
                                v-on="on"
                                :disabled=!fields_paciente.isEditing                            
                                outlined
                                ></v-text-field>
                          </validation-provider>  
                          </template>
                          <span>{{ fields_paciente.tx_nombres.caracteristicas.tooltip  }} </span>
                        </v-tooltip>                
                      </div>
                      <div>
                          <validation-provider
                            v-slot="{ errors }"
                            name="fields_paciente.fe_nacimiento.fe_nacimiento"
                            :rules=fields_paciente.fe_nacimiento.caracteristicas.required
                          >
                              <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="fields_paciente.fe_nacimiento.fe_nacimiento"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="fields_paciente.fe_nacimiento.fe_nacimiento"
                                    :label=fields_paciente.fe_nacimiento.caracteristicas.label
                                    :error-messages="errors"
                                    :prepend-icon=icons.mdiCalendar
                                    v-bind="attrs"
                                    v-on="on"
                                  outlined
                                  :disabled=!fields_paciente.isEditing                            
                                  @blur="calcular_edad()"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fields_paciente.fe_nacimiento.fe_nacimiento"
                                  :max='fecha_reg_max'
                                  :date-format="date => new Date(date).toDateString()" 
                                  :formatted-value.sync="fields_paciente.fe_nacimiento.fe_nacimiento"
                                  locale="es"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(fields_paciente.fe_nacimiento.fe_nacimiento)"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                          </validation-provider>                                     
                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_paciente.nu_edad.nu_edad"
                          :rules=fields_paciente.nu_edad.caracteristicas.required
                        >
                          <v-text-field
                            v-model="fields_paciente.nu_edad.nu_edad"
                            class="input-group--focused text-lg"
                            :error-messages="errors"
                            :label=fields_paciente.nu_edad.caracteristicas.label 
                            maxlength="3"
                            outlined
                            :disabled='true'                            
                          >
                          </v-text-field>

                        </validation-provider>   
                      </div>
                      <div v-if="!isHiddenTieneCedula" class="pb-6">
                          <validation-provider
                            v-slot="{ errors }"                                        
                            name="fields_paciente.bo_posee_cedula.bo_posee_cedula"
                            rules="required"                          
                          >
                          <div class="flex justify-center">
                            <span>Posee cédula de identidad?</span>
                            <div class="flex justify-start">
                              <div class="pl-2 pr-2">
                                <input @change="verificar_bo_posee_cedula()"  checked  v-model="fields_paciente.bo_posee_cedula.bo_posee_cedula"  value="true" :error-messages="errors" class=" rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
                                <label class="form-check-label inline-block text-gray-800" for="flexRadioDefault1">
                                  Si
                                </label>
                              </div>
                              <div class=" pl-2 pr-2">
                                <input @change="verificar_bo_posee_cedula()"  v-model="fields_paciente.bo_posee_cedula.bo_posee_cedula" value="false" :error-messages="errors" class="  rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" >
                                <label class="form-check-label inline-block text-gray-800" for="flexRadioDefault2">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>              
                          </validation-provider>
                      </div>  
                      <div v-if="fields_paciente.bo_posee_cedula.bo_posee_cedula == 'true'">            
                          <validation-provider
                            v-slot="{ errors }"
                            name="fields_paciente.tx_nacionalidad.tx_nacionalidad"
                            :rules=fields_paciente.tx_nacionalidad.caracteristicas.required
                          >
                                  <v-select
                                    v-model="fields_paciente.tx_nacionalidad.tx_nacionalidad"
                                    :items="d_nacionalidad"
                                    class="input-group--focused text-lg"
                                    :placeholder=fields_paciente.tx_nacionalidad.caracteristicas.placeholder
                                    :error-messages="errors"
                                    :label=fields_paciente.tx_nacionalidad.caracteristicas.label
                                    outlined
                                    :disabled=!fields_paciente.isEditing
                                  ></v-select>                      

                          </validation-provider>                    
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="fields_paciente.tx_documento_identidad.tx_documento_identidad"
                                    :rules=fields_paciente.tx_documento_identidad.caracteristicas.required
                                  >

                                    <v-text-field
                                      v-model="fields_paciente.tx_documento_identidad.tx_documento_identidad"
                                      class="input-group--focused text-lg"
                                      :error-messages="errors"
                                      :label=fields_paciente.tx_documento_identidad.caracteristicas.label 
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                      maxlength="10"
                                      :disabled=!fields_paciente.isEditing                            
                                    >
                                    </v-text-field>
                                  </validation-provider>                    
                              </template>
                              <span>{{fields_paciente.tx_documento_identidad.caracteristicas.tooltip  }} </span>
                            </v-tooltip>                
                      </div>

                    <div class="mb-4 w-full bg-gray-200 p-4 rounded-lg" v-if="!isHiddenRepresentante">
                        <div class="flex justify-between text-white">
                            <div class="w-4/5  text-white">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="fields_paciente.nu_id_representante.nu_id_representante"
                                  :rules=fields_paciente.nu_id_representante.caracteristicas.required
                                >
                                      <v-autocomplete
                                          v-model="fields_paciente.nu_id_representante.nu_id_representante"
                                          :items="listaRepresentante"
                                            :loading="isLoading"
                                            :error-messages="errors"
                                            hide-no-data
                                            hide-selected
                                            item-text="tx_nombre_completo"
                                            item-value="nu_id_persona"
                                            :label=fields_paciente.nu_id_representante.caracteristicas.label
                                            :placeholder=fields_paciente.nu_id_representante.caracteristicas.placeholder
                                            prepend-icon=""
                                            outlined
                                            :disabled=!fields_paciente.isEditing  
                                            class="text-white"                          
                                      ></v-autocomplete>

                                </validation-provider>                    
                            </div>
                            <div class="align-middle pt-2">
                                  <button @click="dialogRepresentante = !dialogRepresentante " class="flex w-full text-center text-xs p-2 font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer">

                                          <v-icon
                                          color="white"
                                          >                                
                                          {{ icons.mdiAccountMultiplePlus }}
                                          </v-icon>
                                          <span class="pt-1 pl-1 ">
                                            Agregar Representante                                                
                                          </span>
                                    </button>                           
                            </div>
                         </div>
                         <div>
                              <validation-provider
                                v-slot="{ errors }"
                                name="fields_paciente.nu_id_parentesco.nu_id_parentesco"
                                :rules=fields_paciente.nu_id_parentesco.caracteristicas.required
                              >
                                  <v-select
                                    v-model="fields_paciente.nu_id_parentesco.nu_id_parentesco"
                                    :items="d_parentesco"
                                    class="input-group--focused text-lg pt-4"
                                    :error-messages="errors"
                                    :label=fields_paciente.nu_id_parentesco.caracteristicas.label
                                    outlined
                                    :disabled=!fields_paciente.isEditing                            
                                  ></v-select>                      
                              </validation-provider>   
                         </div>

                    </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_paciente.nu_sexo.nu_sexo"
                          :rules=fields_paciente.nu_sexo.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_paciente.nu_sexo.nu_sexo"
                              :items="d_sexo"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_paciente.nu_sexo.caracteristicas.label
                              outlined
                              :disabled=!fields_paciente.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_paciente.nu_estado_civil.nu_estado_civil"
                          :rules=fields_paciente.nu_estado_civil.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_paciente.nu_estado_civil.nu_estado_civil"
                              :items="d_edo_civil"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_paciente.nu_estado_civil.caracteristicas.label
                              outlined
                              :disabled=!fields_paciente.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_paciente.nu_nivel_educativo.nu_nivel_educativo"
                          :rules=fields_paciente.nu_nivel_educativo.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_paciente.nu_nivel_educativo.nu_nivel_educativo"
                              :items="d_nivel_educativo"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_paciente.nu_nivel_educativo.caracteristicas.label
                              outlined
                              :disabled=!fields_paciente.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>     
                      <div>         
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">

                            <validation-provider
                              v-slot="{ errors }"
                              name="fields_paciente.tx_profesion.tx_profesion"
                              :rules=fields_paciente.tx_profesion.caracteristicas.required
                            >
                              <v-text-field
                                v-model="fields_paciente.tx_profesion.tx_profesion"
                                class="input-group--focused text-lg"
                                :error-messages="errors"
                                :label=fields_paciente.tx_profesion.caracteristicas.label 
                                v-bind="attrs"
                                v-on="on"
                                :disabled=!fields_paciente.isEditing                            
                                outlined
                              >
                              </v-text-field>

                            </validation-provider>   
                          </template>
                          <span>{{fields_paciente.tx_profesion.caracteristicas.tooltip  }} </span>
                        </v-tooltip>                

                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_paciente.nu_mano_dominante.nu_mano_dominante"
                          :rules=fields_paciente.nu_mano_dominante.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_paciente.nu_mano_dominante.nu_mano_dominante"
                              :items="d_mano_dominante"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_paciente.nu_mano_dominante.caracteristicas.label
                              outlined
                              :disabled=!fields_paciente.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>
            </div>

        </div>                  


                  <v-dialog
                      max-width="700px"
                      transition="dialog-bottom-transition" 
                      v-if="dialogRepresentante" :value="true" @input="dialogRepresentante = false"                   
                      >
                      <v-card>
                        <v-container>
                        <div class="w-full mx-auto my-auto">
                          <PacienteRepresentanteRegistrar @guardar_representante_datos_basicos="return_representante" :send_representante="send_datos_basicos" titulo="Registrar usuario_perfil"/>
                        </div>

                        </v-container>

                      </v-card>
                    </v-dialog>              

          
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_PERSONA  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import PacienteRepresentanteRegistrar from "@/components/paciente/PacienteRepresentanteRegistrar.vue";

import "@/common/rules.js";
const moment = require('moment');
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiAccountMultiplePlus
} from '@mdi/js'


export default {
  name: 'PacienteDatosBasicos',
  data () {
    return {
        isHiddenTieneCedula: true,
        dialogRepresentante: false,
        modal: false,                 
        isHiddenRepresentante: true,
        list_representante: [],
        search:null,
        isLoading: false,
        edad: { cantidad: 0, medida: 'año(s)'},
        d_parentesco: [
                  { value: 1, text: 'Padre' },
                  { value: 2, text: 'Madre' },
                  { value: 3, text: 'Hermano / Hermana' },
                  { value: 4, text: 'Primo / Prima' },
                  { value: 5, text: 'Amigo / Amiga', 
                    disabled: false 
                  }
                ],                        
        d_nacionalidad: [
            { value: 'V', text: 'Venezolana' },
            { value: 'E', text: 'Extranjera', 
              disabled: false 
            }
          ],
        d_sexo: [
                  { value: 1, text: 'Femenino' },
                  { value: 2, text: 'Masculino', 
                    disabled: false 
                  }
                ],                        
        d_edo_civil: [
                  { value: 1, text: 'Soltero' },
                  { value: 2, text: 'Casado' },
                  { value: 3, text: 'Concubino' },
                  { value: 4, text: 'Divorciado' },
                  { value: 5, text: 'Viudo', 
                    disabled: false 
                  }
                ],              
        d_mano_dominante: [
                  { value: 1, text: 'Diestro' },
                  { value: 2, text: 'Zurdo' },
                  { value: 3, text: 'Ambidiestro', 
                    disabled: false 
                  }
                ],              
        d_nivel_educativo: [
                  { value: 1, text: 'Iletrado' },
                  { value: 2, text: 'Básica completa' },
                  { value: 3, text: 'Básica incompleta' },
                  { value: 4, text: 'Media Completa' },
                  { value: 5, text: 'Media Incompleta' },
                  { value: 6, text: 'TSU completa' },
                  { value: 7, text: 'TSU incompleta ' },
                  { value: 8, text: 'Universitaria completa' },
                  { value: 9, text: 'Universitaria incompleta ' },
                  { value: 10, text: 'Postgrado', 
                    disabled: false 
                  }
                ],              

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
        mdiAccountMultiplePlus
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search () {
        // Items have already been loaded
        if (this.itemsRepresentante.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        this.$store.dispatch(LIST_PERSONA, {}).then(response => {
            this.list_representante = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        }).finally(() => (this.isLoading = false))      

      },          
  },     
  computed: {
    ...mapGetters(['fields_paciente']),
      fecha_reg_max() {
        return new Date().toISOString().slice(0,10);

      },    
      listaRepresentante () {
          return this.list_representante.map(entry => {
              const Description = entry.tx_nombre_completo.length > this.descriptionLimit
              ? entry.tx_nombre_completo.slice(0, this.descriptionLimit) + '...'
              : entry.tx_nombre_completo

              return Object.assign({}, entry, { Description })
          })
      },             

  },
  props: {
    send_datos_basicos: Object
  },
  components: {
      PacienteRepresentanteRegistrar,
      
  },
  methods: {
    return_representante(msg) {
        //this.buscarPersona('params')
        var guardar_representante = JSON.parse(msg);
        //this.select_paciente = JSON.parse(msg);
        console.log("return_paciente guardar_paciente: " , guardar_representante);
        this.searchRepresentante()
        
        this.$emit("guardar_paciente", guardar_representante);
        this.dialogRepresentante = false
        //this.send_paciente = msg;
    },    
    searchRepresentante(q) {
    this.$store.dispatch(LIST_PERSONA, {q: q}).then(response => {
        console.log('searchRepresentante: ', response )
        this.list_representante = response.rest.items
    }, error => {
        this.$store.commit(SET_ERROR, error.statusText)
    })      
    },      
    calcular_edad() {
        const today = new Date();
        this.fields_paciente.bo_posee_cedula.bo_posee_cedula = false
        this.edad = { cantidad: 0, medida: 'año(s)'}
        var fecha2 = moment(today);
        var fecha1 = moment(this.fields_paciente.fe_nacimiento.fe_nacimiento);      
        
        this.edad.cantidad = fecha2.diff(fecha1, 'years')

        if (this.edad.cantidad < 1) {
          this.edad.cantidad = fecha2.diff(fecha1, 'months')
          this.isHiddenRepresentante = false
          this.isHiddenTieneCedula = true
          if (this.edad.cantidad < 1) {
            this.edad.cantidad = fecha2.diff(fecha1, 'days')
            this.edad.medida = ' día(s)'
          } else {
            this.edad.medida =  ' mes (es)'
          } 
        } else {
            this.edad.medida = ' año(s)'
            this.isHiddenRepresentante = false
            if (this.edad.cantidad > 8 && this.edad.cantidad < 18) {
              this.isHiddenTieneCedula = false
            } else {
              if (this.edad.cantidad >= 18) {
                this.isHiddenRepresentante = true
                this.isHiddenTieneCedula = true
              } else {
                if (this.edad.cantidad < 9) {
                  this.isHiddenTieneCedula = true
                } else {
                  this.isHiddenTieneCedula = false
                }
              }
            }
        } 
        this.fields_paciente.nu_edad.caracteristicas.label =  'Edad (' + this.edad.medida + ') *' 
        this.fields_paciente.nu_edad.nu_edad = this.edad.cantidad
        //return edad
    },
    verificar_edad() {
      console.log("fecha nacimiento: ", this.fields_paciente.bo_es_menor.bo_es_menor)
      switch(this.fields_paciente.bo_es_menor.bo_es_menor) {
        case "true":
          this.isHiddenTieneCedula = false
          break;
        default:
          this.isHiddenTieneCedula = true
          // code block
      }    
    },    
    verificar_bo_posee_cedula() {
      console.log("this.isHiddenTieneCedula: ", this.fields_paciente.bo_posee_cedula.bo_posee_cedula)
      switch(this.fields_paciente.bo_posee_cedula.bo_posee_cedula) {
        case "true":
          this.isHiddenRepresentante = true
          break;
        default:
          this.isHiddenRepresentante = false
          // code block
      }    
    }, 


  },
  
  mounted() {
        this.searchRepresentante()
        if (this.send_datos_basicos.operacion === 'EDITAR') {
            for (var prop in this.send_datos_basicos.paciente) {
            this.fields_paciente[prop][prop] = this.send_datos_basicos.paciente[prop]
            }
            if (this.send_datos_basicos.paciente.bo_es_menor === 0) {
                this.fields_paciente["bo_es_menor"]["bo_es_menor"] = false
            } else {
                this.fields_paciente["bo_es_menor"]["bo_es_menor"] = true
            }
            if (this.send_datos_basicos.paciente.bo_posee_cedula === 0) {
               //this.fields_paciente.bo_posee_cedula.bo_posee_cedula = false
            } else {
                this.fields_paciente.bo_posee_cedula.bo_posee_cedula = true
            }

        }           
    
    //if (this.send_datos_basicos.operacion === 'EDITAR') {

    /*         for (const property in this.send_datos_basicos.paciente) {
              if(typeof this.fields_paciente[property] === 'object') {
                if (this.fields_paciente[property]['caracteristicas']['tipo'] === 'JSON') {
                  this.fields_paciente[property][property] = JSON.parse(this.send_datos_basicos.paciente[property])
                } else {
                  this.fields_paciente[property][property] = this.send_datos_basicos.paciente[property]
                }

              }
            }
 */
/*       for (var prop in this.send_datos_basicos.paciente) {
        console.log("Editar: ", prop, this.fields_paciente[prop]['caracteristicas'])
      //this.fields_paciente[prop][prop] = this.send_datos_basicos.paciente[prop]
      }
      if (this.send_datos_basicos.paciente.bo_es_menor === 0) {
        this.fields_paciente.bo_es_menor.bo_es_menor = false
      } else {
        this.fields_paciente.bo_es_menor.bo_es_menor = true
      }
 */
    //}           
  },
    
}
</script>