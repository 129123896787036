<template>
    <div v-if="titulo" class="md:p-2">
        <div class="p-3 text-xl md:text-xl text-skin-base bg-skin-header uppercase rounded-lg w-full">
                    {{  titulo }}                    
        </div>        
    </div>
</template>

<script>
import {
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"

export default {
    name: 'HeaderModulo',
    data() {
        return {
        }
    },
    props: {
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {

    }, 
    components: {
    }     
}
</script>