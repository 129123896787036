 <template>
  <div>
      <div class="w-full">
        <PacienteHeader :titulo="titulo" /> 
      </div>
      <div class="w-full">
        <PacienteOpciones @guardar_paciente="return_paciente"/>
      </div>
      <div class="w-full">
          <PacienteListar :pacientes="datos_paciente"></PacienteListar>
      </div>

  </div>
</template>

<script>
import PacienteHeader from "@/components/paciente/PacienteHeader.vue";
import PacienteOpciones from "@/components/paciente/PacienteOpciones.vue";
import PacienteListar from "@/components/paciente/PacienteListar.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { MUESTRA_PACIENTE } from '@/store/actions.type'

export default {
  name: 'Paciente',
  data () {
    return {
      titulo: 'Paciente',
      datos_paciente: [],
      send_paciente: {},

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    PacienteHeader,
    PacienteOpciones,
    PacienteListar
  //  ThemifyIcon    
  },  
  watch: {
      datos_paciente () {
          return this.datos_paciente
      },

  },  
  methods: {
    searchPaciente(q) {
      this.$store.dispatch(MUESTRA_PACIENTE, {q: q}).then(response => {

          this.datos_paciente = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_paciente(msg) {
      //this.buscarPersona('params')
      console.log("select_representante: ", msg );
       console.log("select_representante: ", msg.btn_guardar );
      //var guardar_paciente = JSON.parse(msg);

      
      if (msg.btn_guardar == 'true') {
        this.searchPaciente({})
      }
      //this.send_paciente = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_paciente = msg;
        //this.select_paciente = JSON.parse(msg);
        console.log("return_consulta_medica guardar_paciente update: " , guardar_paciente);

        
        if (guardar_paciente.btn_guardar == 'true') {
            this.searchPaciente({})
            //this.fields.paciente = this.select_paciente.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_paciente.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_paciente.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_paciente = msg;
    },        

  },
  mounted () {
    this.searchPaciente({})

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
