<template>
    <div>
        <div  v-if="this.fields_paciente.bo_posee_cedula == 'false'">
            <v-btn
                color="primary"
                class="w-full"
                @click="tomar_ubi_geo()"
            >
                Tomar los datos del Representante
            </v-btn>                        
        </div> 
        <validation-provider
        v-slot="{ errors }"
        name="fields_paciente.json_estado_co.json_estado_co"
        :rules=fields_paciente.json_estado_co.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_paciente.json_estado_co.json_estado_co"
            :items="listaEntidades"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_desc_entidad"
            item-value="tx_cod_entidad"
            :label=fields_paciente.json_estado_co.caracteristicas.label
            :placeholder=fields_paciente.json_estado_co.caracteristicas.placeholder
            prepend-icon=""
            return-object
            outlined
            @change="searchMunicipio"
            :disabled=!fields_paciente.isEditing                            
            ></v-autocomplete>
        </validation-provider>

        <validation-provider
        v-slot="{ errors }"
        name="fields_paciente.json_municipio_co.json_municipio_co"
        :rules=fields_paciente.json_municipio_co.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_paciente.json_municipio_co.json_municipio_co"
            :items="codigosMunicipio"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_desc_municipio"
            item-value="tx_cod_municipio"
            :label=fields_paciente.json_municipio_co.caracteristicas.label
            :placeholder=fields_paciente.json_municipio_co.caracteristicas.placeholder
            prepend-icon=""
            return-object
            outlined
            @change="searchParroquia"
            :disabled=!fields_paciente.isEditing
            ></v-autocomplete>
        </validation-provider>
        <validation-provider
        v-slot="{ errors }"
        name="fields_paciente.json_parroquia_co.json_parroquia_co"
        :rules=fields_paciente.json_parroquia_co.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_paciente.json_parroquia_co.json_parroquia_co"
            :items="codigosParroquia"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_desc_parroquia"
            item-value="tx_cod_parroquia"
            :label=fields_paciente.json_parroquia_co.caracteristicas.label
            :placeholder=fields_paciente.json_parroquia_co.caracteristicas.placeholder
            prepend-icon=""
            outlined
            return-object
            :disabled=!fields_paciente.isEditing
            ></v-autocomplete>
        </validation-provider>
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

            <validation-provider
            v-slot="{ errors }"
            name="fields_paciente.tx_direccion.tx_direccion"
            rules="required"
            >
                <v-textarea
                    v-model="fields_paciente.tx_direccion.tx_direccion"
                    auto-grow
                    v-bind="attrs"
                    v-on="on"
                    class="input-group--focused text-lg"
                    :error-messages="errors"
                    :label=fields_paciente.tx_direccion.caracteristicas.label                
                    outlined
                    :disabled=!fields_paciente.isEditing
                ></v-textarea>                      
            </validation-provider>   
        </template>
        <span>{{ fields_paciente.tx_direccion.caracteristicas.tooltip  }} </span>
        </v-tooltip>                

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {  MUESTRA_ENTIDAD, MUESTRA_MUNICIPIO,MUESTRA_PARROQUIA  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

    export default {
        name: 'PacienteDatosUbigeo',
        data() {
            return {
                isHiddenRepresentante: true,
                codigosEntidad: [],
                codigosMunicipio: [],
                codigosParroquia: [],
                isLoading: false,
                selected: {
                    json_estado_co: [],
                    json_municipio_co: [],
                    json_parroquia_co: [],
                },                
            }
        },
        props: {
            send_datos_basicos: Object
        },
        computed: {
            ...mapGetters(['fields_paciente']),
            listaEntidades () {
                return this.codigosEntidad.map(entry => {
                    const Description = entry.tx_desc_entidad.length > this.descriptionLimit
                    ? entry.tx_desc_entidad.slice(0, this.descriptionLimit) + '...'
                    : entry.tx_desc_entidad

                    return Object.assign({}, entry, { Description })
                })
            },             
        },
        methods: {
            searchEntidad(q) {
            this.$store.dispatch(MUESTRA_ENTIDAD, {q: q}).then(response => {
                this.codigosEntidad = response.rest.items
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
            })      
            },      
            searchMunicipio(value) {
                this.codigosMunicipio = []
                this.codigosParroquia = []
                this.fields_paciente.json_municipio_co.json_municipio_co = []
                this.$store.dispatch(MUESTRA_MUNICIPIO, {tx_cod_entidad: value.tx_cod_entidad }).then(response => {
                    this.codigosMunicipio = response.rest.items
                }, error => {
                    this.$store.commit(SET_ERROR, error.statusText)
                })      
            },        
            searchParroquia(value) {
                console.log('Parroquia: ', value)
                this.$store.dispatch(MUESTRA_PARROQUIA, {tx_cod_entidad: value.tx_cod_entidad, tx_cod_municipio: value.tx_cod_municipio  }).then(response => {
                    this.codigosParroquia = response.rest.items
                }, error => {
                    this.$store.commit(SET_ERROR, error.statusText)
                })      
            },
            tomar_ubi_geo() {
                this.fields_paciente.json_estado_co = this.fields_paciente.select_representante.json_cod_entidad
                this.searchMunicipio(this.fields_paciente.select_representante.json_cod_entidad)
                this.fields_paciente.json_municipio_co = this.fields_paciente.select_representante.json_cod_municipio
                this.searchParroquia(this.fields_paciente.select_representante.json_cod_municipio)
                this.fields_paciente.json_parroquia_co = this.fields_paciente.select_representante.json_cod_parroquia
                this.fields_paciente.tx_direccion = this.fields_paciente.select_representante.tx_direccion
            },
        },
        mounted() {
            this.searchEntidad()
            console.log('Ubigeo: ', this.send_datos_basicos)
            if (this.send_datos_basicos.operacion === 'EDITAR') {
                for (var prop in this.send_datos_basicos.paciente) {
                this.fields_paciente[prop][prop] = this.send_datos_basicos.paciente[prop]
                }
                if (this.send_datos_basicos.paciente.bo_es_menor === 0) {
                    this.fields_paciente["bo_es_menor"]["bo_es_menor"] = false
                } else {
                    this.fields_paciente["bo_es_menor"]["bo_es_menor"] = true
                }

            this.fields_paciente.json_estado_co.json_estado_co = JSON.parse(this.send_datos_basicos.paciente["json_estado_co"])
            this.searchMunicipio(JSON.parse(this.send_datos_basicos.paciente["json_estado_co"]))
            this.fields_paciente.json_municipio_co.json_municipio_co = JSON.parse(this.send_datos_basicos.paciente["json_municipio_co"])
            this.searchParroquia(JSON.parse(this.send_datos_basicos.paciente["json_municipio_co"]))
            this.fields_paciente.json_parroquia_co.json_parroquia_co = JSON.parse(this.send_datos_basicos.paciente["json_parroquia_co"])
            }           

            this.isHiddenTieneCedula = this.fields_paciente.bo_posee_cedula.bo_posee_cedula
        }
        
    }
</script>
