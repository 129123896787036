import { render, staticRenderFns } from "./PacienteDatosRedesSociales.vue?vue&type=template&id=3791cd3a&scoped=true&"
import script from "./PacienteDatosRedesSociales.vue?vue&type=script&lang=js&"
export * from "./PacienteDatosRedesSociales.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3791cd3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTextField,VTooltip})
