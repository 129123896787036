var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_facebook.tx_facebook","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":_vm.fields_paciente.tx_facebook.caracteristicas.label,"disabled":!_vm.fields_paciente.isEditing,"outlined":""},model:{value:(_vm.fields_paciente.tx_facebook.tx_facebook),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_facebook, "tx_facebook", $$v)},expression:"fields_paciente.tx_facebook.tx_facebook"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_facebook.caracteristicas.tooltip)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_instagram.tx_instagram","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":_vm.fields_paciente.tx_instagram.caracteristicas.label,"disabled":!_vm.fields_paciente.isEditing,"outlined":""},model:{value:(_vm.fields_paciente.tx_instagram.tx_instagram),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_instagram, "tx_instagram", $$v)},expression:"fields_paciente.tx_instagram.tx_instagram"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_instagram.caracteristicas.tooltip)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_paciente.tx_twitter.tx_twitter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":_vm.fields_paciente.tx_twitter.caracteristicas.label,"disabled":!_vm.fields_paciente.isEditing,"outlined":""},model:{value:(_vm.fields_paciente.tx_twitter.tx_twitter),callback:function ($$v) {_vm.$set(_vm.fields_paciente.tx_twitter, "tx_twitter", $$v)},expression:"fields_paciente.tx_twitter.tx_twitter"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_paciente.tx_twitter.caracteristicas.tooltip)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }